import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { ArrowLink } from '../Atoms'
import Layout from '../Layout'

import { labels, localeUrls } from '../../langs/langs'

import cahrtI from '../../images/chart.svg'
import docuI from '../../images/docu.svg'

const ClientZone = ({ localeID, location }) => {
  return (
    <Layout location={location} title={labels[localeID]['CZONE']} localeID={localeID}>
      <div className="lg:h-[calc(100vh_-_3.75rem)] min-h-max relative grid md:grid-cols-2">
        <div className="hcenter justify-center h-full px-16 md:px-32 py-16">
          <a href="/" className="group">
            <div className="hcenter justify-center">
              <img src={cahrtI} />
              <h2 className="font-bold text-2xl -tracking-[0.1px]">
                {labels[localeID]['DIGI_REP']}
              </h2>
            </div>
            <StaticImage
              src="../../images/bonus2.png"
              className="max-h-full h-full my-20 group-hover:scale-105 duration-500 max-w-2xl"
              objectFit="contain"
            />
            <ArrowLink className="text-blue justify-center text-lg" span={true}>
              {labels[localeID]['REPORTING']}
            </ArrowLink>
          </a>
        </div>
        <div className="hcenter justify-center h-full px-16 md:px-32 py-16 bg-light">
          <a href="https://dataroom.ccstax.sk/" className="group" target="_blank">
            <div className="hcenter justify-center">
              <img src={docuI} />
              <h2 className="font-bold text-2xl -tracking-[0.1px]">{labels[localeID]['CLOUD']}</h2>
            </div>
            <StaticImage
              src="../../images/bonus3.png"
              className="max-h-full h-full my-20 group-hover:scale-105 duration-500 max-w-2xl"
              objectFit="contain"
            />
            <ArrowLink className="text-blue justify-center text-lg" span={true}>
              {labels[localeID]['DOCU_SHARE']}
            </ArrowLink>
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default ClientZone
